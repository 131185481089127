<script>
	import { _, locale } from 'svelte-i18n';
	import { slide, fade } from 'svelte/transition';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '$lib/TitleBar.svelte';
	import { showSpinner } from '$src/stores.js';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import VerifyPhone from '$lib/VerifyPhone.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import PointerIcon from '$lib/icon/PointerIcon.svelte';
	import { data, notification } from '$src/stores.js';
	import WizardProgress from '$lib/WizardProgress.svelte';
	import Dropdown from '$lib/Dropdown.svelte';
	import logins from '$svr/providers/logins.json';
	import {
		getProfile,
		postLinkEth,
		postLinkEthChallenge,
		keepAlive,
		postLinkProvider
	} from '$utils/api.js';
	import LoginProviderGroup from '$lib/LoginProviderGroup.svelte';
	import { onMount } from 'svelte';
	import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte';
	import Notification from '$lib/Notification.svelte';
	import { setAttributes } from '$utils/common.js';
	import { getWallet, trimEthAddress } from '$utils/ethereum.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	// import { WALLETCONNECT_CONFIG } from '$src/constants.js'
	// import { getAddressFromAccount } from '$utils';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	import LoginProvider from '$lib/LoginProvider.svelte';

	// let web3ModalSign;

	let continueWithEmail = false;
	let continueWithPhone = false;

	let ethereumProgressModal = null;
	let ethereumProgressNotifs = [];

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
				if (!$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}
				if ($data.actions?.doneWizardAt) return replaceRoute('/');
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.preferred?.length) return replaceRoute('/wizard/preferredprovider');

		nextPage();

		localStorage.setItem('currentWizardStage', 'recoveryprovider');
		logWizardFunnelPlausibleEvent();
		logPlausibleEvent({ u: '/wizard/recoveryprovider' });
		$showSpinner = false;
	});

	function logWizardFunnelPlausibleEvent() {
		//Wizard Funnel
		const indexOfCurrentFunnelStep = window.wizardFunnel.indexOf(sessionStorage.wiz_funnel);
		const indexOfNextFunnelStep = window.wizardFunnel.indexOf('wiz_recovery');
		//wizard funnel state is valid and not already sent
		if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			logPlausibleEvent({
				n: 'Wiz Recovery',
				p: { preferred, welcome_email_app },
				u: '/wizard/recoveryprovider'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_recovery');
		}
	}

	function nextPage() {
		const preferred = $data.profile?.accounts?.find((i) => i.preferred);
		const nonSocialProviders = ['email', 'phone'];
		const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
		const socialAccountLinked = $data.profile?.accounts.filter(
			(i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug) && !i.managed
		).length;
		const recoveries = $data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);

		if (recoveries?.length >= 2) {
			if (!isPreferredSocial && socialAccountLinked) {
				return navigateTo('/wizard/upgrade');
			} else {
				return navigateTo('/wizard/status');
			}
		}
	}

	async function verifyEmailSuccess() {
		//Wizard Funnel
		if (sessionStorage.wiz_funnel) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $data?.recovery?.[0]?.slug;
			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 1 Success',
					p: { preferred, welcome_email_app, recovery_1: 'email' },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 2 Success',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
			}
		}
		try {
			continueWithEmail = false;
			$data = await getProfile();
			nextPage();
		} catch (err) {
			console.error(err);
		}
	}

	async function verifyPhoneSuccess() {
		//Wizard Funnel
		if (sessionStorage.wiz_funnel) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $data?.recovery?.[0]?.slug;
			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 1 Success',
					p: { preferred, welcome_email_app, recovery_1: 'email' },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 2 Success',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: 'email' },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
			}
		}
		try {
			continueWithPhone = false;
			$data = await getProfile();
			nextPage();
		} catch (err) {
			console.error(err);
		}
	}

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;
		const slug = getWallet().slug;
		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/extension/${slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		//Wizard Funnel
		if (sessionStorage.wiz_funnel) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $data?.recovery?.[0]?.slug;
			if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 1 Start',
					p: { preferred, welcome_email_app, recovery_1: slug },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
			} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
				await logPlausibleEvent({
					n: 'Wiz Recovery 2 Start',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
					u: '/wizard/recoveryprovider'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
			}
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Linking wallet')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				notification.show($_(`You've rejected the sign request`), 'error');
			} else {
				notification.show($_('Something went wrong. Please try again later.'), 'error');
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			notification.show(
				$_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				'error'
			);
			logPlausibleEvent({ u: `/link/ethereum/extension/${slug}`, n: 'action' });
			$data = await getProfile();

			//Wizard Funnel
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $data?.recovery?.[0]?.slug;
			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 1 Success',
					p: { preferred, welcome_email_app, recovery_1: slug },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 2 Success',
					p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
			}

			nextPage();
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect() {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch (err) {
	// 		console.error(err);
	// 		setTimeout(() => {
	// 			//tbd : remove timeout - something is unsetting notification here
	// 			notification.show(
	// 				'Something went wrong',
	// 				'error'
	// 			);
	// 		}, 150);
	// 		return;
	// 	}

	// 	if (session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address);
	// 	}, 1000);
	// }

	// async function continueWalletConnectSigning(address) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLinkEth(address);
	// 		logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		notification.show(
	// 			'Something went wrong',
	// 			'error'
	// 		);
	// 		return;
	// 	}
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];

	// 	//Wizard Funnel
	// 	if (sessionStorage.wiz_funnel) {
	// 		const preferred = $data?.preferred?.[0]?.slug;
	// 		let welcome_email_app;
	// 		if (sessionStorage.welcome_app_info) {
	// 			try {
	// 				welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
	// 			} catch (err) {
	// 				console.error(err);
	// 			}
	// 		}
	// 		const recovery_1 = $data?.recovery?.[0]?.slug;
	// 		if (!recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery') {
	// 			await logPlausibleEvent({
	// 				n: 'Wiz Recovery 1 Start',
	// 				p: { preferred, welcome_email_app, recovery_1: slug },
	// 				u: '/wizard/recoveryprovider'
	// 			});
	// 			sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_start');
	// 		} else if (recovery_1 && sessionStorage.wiz_funnel === 'wiz_recovery_1_success') {
	// 			await logPlausibleEvent({
	// 				n: 'Wiz Recovery 2 Start',
	// 				p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
	// 				u: '/wizard/recoveryprovider'
	// 			});
	// 			sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_start');
	// 		}
	// 	}

	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_('Message signed'),
	// 				type: 'success',
	// 				status: $_('Linking wallet')
	// 			}
	// 		];
	// 	} catch (err) {
	// 		console.info(err);
	// 		notification.show(
	// 			$_(`You've rejected the sign request`),
	// 			'error'
	// 		);
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name
	// 	};

	// 	try {
	// 		await postLinkEthChallenge(body);
	// 		logPlausibleEvent({
	// 			u: `/link/ethereum/walletconnect/${slug}`,
	// 			n: 'action'
	// 		});
	// 		notification.show(
	// 			$_('{provider} {label} has been added', {
	// 				values: {
	// 					provider: body.name,
	// 					label: trimEthAddress(address)
	// 				}
	// 			}),
	// 			'success'
	// 		);
	// 		$data = await getProfile();

	// 		//Wizard Funnel
	// 		if (sessionStorage.wiz_funnel) {
	// 			const preferred = $data?.preferred?.[0]?.slug;
	// 			let welcome_email_app;
	// 			if (sessionStorage.welcome_app_info) {
	// 				try {
	// 					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
	// 				} catch (err) {
	// 					console.error(err);
	// 				}
	// 			}
	// 			const recovery_1 = $data?.recovery?.[0]?.slug;
	// 			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
	// 				logPlausibleEvent({
	// 					n: 'Wiz Recovery 1 Success',
	// 					p: { preferred, welcome_email_app, recovery_1: slug },
	// 					u: '/'
	// 				});
	// 				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
	// 			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
	// 				logPlausibleEvent({
	// 					n: 'Wiz Recovery 2 Success',
	// 					p: { preferred, welcome_email_app, recovery_1, recovery_2: slug },
	// 					u: '/'
	// 				});
	// 				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
	// 			}
	// 		}

	// 		nextPage();
	// 	} catch (err) {
	// 		console.error(err);
	// 	} finally {
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 	}
	// }

	function skip() {
		keepAlive();
		const preferred = $data.profile?.accounts?.find((i) => i.preferred);
		const nonSocialProviders = ['email', 'phone'];
		const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
		const socialAccountLinked = $data.profile?.accounts.filter(
			(i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug) && !i.managed
		).length;
		const recoveries = $data.profile?.accounts.filter(
			(i) => i.recovery && !i.preferred && !i.communal
		);

		if (!isPreferredSocial && socialAccountLinked) {
			return navigateTo('/wizard/upgrade');
		} else if (recoveries.length < 2) {
			return navigateTo('/wizard/incomplete');
		} else {
			return navigateTo('/wizard/status');
		}
	}

	const delay = 50;

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'email',
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

{#if $data?.isPersonalLoggedIn && !$showSpinner}
	<main class="flex-1 px-3 md:px-0 overflow-y-auto pb-16">
		<WizardProgress compact />

		<div>
			<h1
				class="md:hidden mb-2 border-none text-base text-center md:text-2xl block font-medium"
				in:fade={{ delay: 4 * delay }}
			>
				{#if $data.profile?.accounts?.find((i) => i.recovery && !i.preferred)}
					{$_('Add second recovery provider')}
				{:else}
					{$_('Add first recovery provider')}
				{/if}
			</h1>
			<p
				class="md:hidden text-xs text-center md:text-sm mx-auto italic"
				in:fade={{ delay: 4 * delay }}
			>
				{$_('A recovery provider could be shared with someone else')}
			</p>
			<div class="max-w-md md:max-w-2xl mt-6 mx-auto flex items-center justify-between text-sm">
				<!-- svelte-ignore a11y-autofocus -->
				<button
					data-test="back-btn"
					on:click={() => {
						keepAlive();
						navigateTo('/wizard/status');
					}}
					class="group w-20 relative inline-flex items-center justify-center invisible {$locale &&
					$locale.startsWith('ar')
						? 'mr-4'
						: 'ml-4'}"
					in:fade={{ delay: 4 * delay }}
				>
					<span class="absolute {$locale && !$locale.startsWith('ar') ? 'ml-2' : ''}"
						>{$_('Back')}</span
					>
					<PointerIcon dir="left" />
				</button>
				<h1
					class="hidden md:block text-center border-none text-base md:text-2xl w-96 font-medium"
					in:fade={{ delay: 4 * delay }}
				>
					{#if $data.profile?.accounts?.find((i) => i.recovery && !i.preferred)}
						{$_('Add second recovery provider')}
					{:else}
						{$_('Add first recovery provider')}
					{/if}
				</h1>
				<button
					data-test="skip-btn"
					on:click={skip}
					class="group w-20 relative inline-flex items-center justify-center {$locale &&
					$locale.startsWith('ar')
						? 'ml-4'
						: 'mr-4'}"
					in:fade={{ delay: 4 * delay }}
				>
					<span class="absolute mr-1">
						{$_('Skip')}
					</span>
					<!-- Next arrow -->
					<PointerIcon dir="right" />
				</button>
			</div>
			<div class="md:max-w-2xl mx-auto mt-2 text-center">
				<p
					class="hidden md:block text-xs md:text-sm md:w-3/4 mx-auto italic"
					in:fade={{ delay: 4 * delay }}
				>
					{$_('A recovery provider could be shared with someone else')}
				</p>

				<section class="mt-6 max-w-md mx-auto" in:fade={{ delay: 5 * delay }}>
					<LoginProviderGroup
						prefix="Add"
						on:ethereum={continueWithEthereumExtension}
						on:managedEmailSuccess={verifyEmailSuccess}
					>
						<Dropdown
							dataTest="add-email-btn"
							expanded={continueWithEmail}
							ariaLabel={$data.profile?.accounts?.find(
								(i) => i.slug === 'email' && i.recovery && !i.managed
							)
								? $_('Add another email')
								: $_('Add email')}
							on:click={() => {
								continueWithEmail = !continueWithEmail;
								continueWithPhone = false;
							}}
						>
							<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
								<MailIcon />
								<span class="block text-left" aria-hidden="true">
									{$data.profile?.accounts?.find(
										(i) => i.slug === 'email' && i.recovery && !i.managed
									)
										? $_('Add another email')
										: $_('Add email')}
								</span>
							</div>
							{#if continueWithEmail}
								<div class="px-3 pb-3 pt-1" transition:slide|local>
									<VerifyEmail on:success={verifyEmailSuccess} />
									<section class="space-y-3 mt-5">
										{#each logins.filter((i) => i.claims.verified_email) as provider}
											<LoginProvider
												on:click={(e) => continueWithProvider(provider.slug, e.detail)}
												{provider}
												prefix="Get email from"
											/>
										{/each}
									</section>
								</div>
							{/if}
						</Dropdown>

						<Dropdown
							dataTest="add-phone-btn"
							expanded={continueWithPhone}
							ariaLabel={$data.profile?.accounts?.find(
								(i) => i.slug === 'phone' && i.recovery && !i.managed
							)
								? $_('Add another phone')
								: $_('Add phone')}
							on:click={() => {
								continueWithPhone = !continueWithPhone;
								continueWithEmail = false;
							}}
						>
							<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
								<PhoneIcon />
								<span class="block text-left" aria-hidden="true">
									{$data.profile?.accounts?.find(
										(i) => i.slug === 'phone' && i.recovery && !i.managed
									)
										? $_('Add another phone')
										: $_('Add phone')}
								</span>
							</div>

							{#if continueWithPhone}
								<div class="px-3 pb-3 pt-1" transition:slide|local>
									<VerifyPhone on:success={verifyPhoneSuccess} />
								</div>
							{/if}
						</Dropdown>
					</LoginProviderGroup>
				</section>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
