<script>
	import { _, locale } from 'svelte-i18n';
	import { data as profileData } from '$src/stores.js';
	import { slide } from 'svelte/transition';
	import { dedupe } from '$utils/common';
	import { flip } from 'svelte/animate';
	import RemoveIconButton from '$lib/button/RemoveIconButton.svelte';
	import Modal from '$lib/modal/Modal.svelte';
	import VerifyName from '$lib/VerifyName.svelte';
	import RemoveButton from '$lib/button/RemoveButton.svelte';
	import { deleteName, putName } from '$utils/api.js';

	const flip_animation_ms = 2000;

	let confirmFullNameDelete,
		confirmPreferredNameDelete,
		confirmFirstNameDelete,
		confirmLastNameDelete;
	let showAddFullNameModal = false,
		showAddPreferredNameModal = false,
		showAddFirstNameModal = false,
		showAddLastNameModal = false;

	let removeFullNameAjax = false;
	async function removeFullName(index) {
		try {
			removeFullNameAjax = confirmFullNameDelete;
			const { names } = await deleteName('name', index);
			$profileData.profile.names = names;
			confirmFullNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeFullNameAjax = null;
		}
	}

	let addFullNameAjax = false;
	async function addFullName(value) {
		addFullNameAjax = true;
		try {
			const { names } = await putName('name', value);
			$profileData.profile.names = names;
			showAddFullNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addFullNameAjax = false;
		}
	}

	let removePreferredNameAjax = false;
	async function removePreferredName(index) {
		try {
			removePreferredNameAjax = confirmPreferredNameDelete;
			const { nicknames } = await deleteName('nickname', index);
			$profileData.profile.nicknames = nicknames;
			confirmPreferredNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removePreferredNameAjax = null;
		}
	}

	let addPreferredNameAjax = false;
	async function addPreferredName(value) {
		addPreferredNameAjax = true;
		try {
			const { nicknames } = await putName('nickname', value);
			$profileData.profile.nicknames = nicknames;
			showAddPreferredNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addPreferredNameAjax = false;
		}
	}

	let removeFirstNameAjax = false;
	async function removeFirstName(index) {
		try {
			removeFirstNameAjax = confirmFirstNameDelete;
			const { given_names } = await deleteName('given_name', index);
			$profileData.profile.given_names = given_names;
			confirmFirstNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeFirstNameAjax = null;
		}
	}

	let removeLastNameAjax = false;
	async function removeLastName(index) {
		try {
			removeLastNameAjax = confirmLastNameDelete;
			const { family_names } = await deleteName('family_name', index);
			$profileData.profile.family_names = family_names;
			confirmLastNameDelete = false;
		} catch (err) {
			console.error(err);
		} finally {
			removeLastNameAjax = null;
		}
	}

	let addFirstNameAjax = false;
	async function addFirstName(value) {
		addFirstNameAjax = true;
		try {
			const { given_names } = await putName('given_name', value);
			$profileData.profile.given_names = given_names;
			showAddFirstNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addFirstNameAjax = false;
		}
	}

	let addLastNameAjax = false;
	async function addLastName(value) {
		addLastNameAjax = true;
		try {
			const { family_names } = await putName('family_name', value);
			$profileData.profile.family_names = family_names;
			showAddLastNameModal = false;
		} catch (err) {
			console.error(err);
		} finally {
			addLastNameAjax = false;
		}
	}
</script>

<div transition:slide|local class="mt-4">
	<!-- Full Names -->
	<section data-test="name-section" class="py-2">
		<h3 class="opacity-80 text-sm">
			{$_('Full Names')}
		</h3>

		{#if $profileData.profile.names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe($profileData.profile.names) as value, index (value)}
					<li
						data-test={`name-item-${index}`}
						class="relative flex items-center flex-shrink-0"
						animate:flip={{ duration: flip_animation_ms }}
						transition:slide|local
					>
						<div class="w-6 h-6 inline-flex items-center">
							{#if $profileData.profile.names.length > 1}
								<RemoveIconButton
									dataTest="remove-name-btn"
									on:click={() => (confirmFullNameDelete = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if confirmFullNameDelete && confirmFullNameDelete === value}
							<Modal
								dataTest="name-confirm-deletion-modal"
								position="left"
								class="md:min-w-[22rem] top-10"
								on:close={() => (confirmFullNameDelete = null)}
							>
								<div class="flex flex-col md:flex-row items-center justify-between">
									<span>{$_('Confirm Deletion')}</span>
									<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-name-btn"
											on:click={() => (confirmFullNameDelete = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-name-btn"
											on:click={() => removeFullName(index)}
											isLoading={removeFullNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-name-btn"
			on:click={() => (showAddFullNameModal = true)}
			class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add full name')}</button
		>

		{#if showAddFullNameModal}
			<Modal position="left" on:close={() => (showAddFullNameModal = false)}>
				<VerifyName type="name" addName={addFullName} ajaxRequest={addFullNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- Preferred Names -->
	<section data-test="nickname-section" class="py-2">
		<h3 class="opacity-80 text-sm">
			{$_('Preferred Names')}
		</h3>

		{#if $profileData.profile.nicknames?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe($profileData.profile.nicknames) as value, index (value)}
					<li
						data-test={`nickname-item-${index}`}
						class="relative flex items-center flex-shrink-0"
						animate:flip={{ duration: flip_animation_ms }}
						transition:slide|local
					>
						<div class="w-6 h-6 inline-flex items-center">
							{#if $profileData.profile.nicknames.length > 1}
								<RemoveIconButton
									dataTest="remove-nickname-btn"
									on:click={() => (confirmPreferredNameDelete = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if confirmPreferredNameDelete && confirmPreferredNameDelete === value}
							<Modal
								dataTest="nickname-confirm-deletion-modal"
								position="left"
								class="md:min-w-[22rem] top-10"
								on:close={() => (confirmPreferredNameDelete = null)}
							>
								<div class="flex flex-col md:flex-row items-center justify-between">
									<span>{$_('Confirm Deletion')}</span>
									<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-nickname-btn"
											on:click={() => (confirmPreferredNameDelete = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-nickname-btn"
											on:click={() => removePreferredName(index)}
											isLoading={removePreferredNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-nickname-btn"
			on:click={() => (showAddPreferredNameModal = true)}
			class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add preferred name')}</button
		>

		{#if showAddPreferredNameModal}
			<Modal position="left" on:close={() => (showAddPreferredNameModal = false)}>
				<VerifyName type="nickname" addName={addPreferredName} ajaxRequest={addPreferredNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- First Names -->
	<section data-test="given_name-section" class="py-2">
		<h3 class="opacity-80 text-sm">
			{$_('First Names')}
		</h3>

		{#if $profileData.profile.given_names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe($profileData.profile.given_names) as value, index (value)}
					<li
						data-test="given_name-item-{index}"
						class="relative flex items-center flex-shrink-0"
						animate:flip={{ duration: flip_animation_ms }}
						transition:slide|local
					>
						<div class="w-6 h-6 inline-flex items-center">
							{#if $profileData.profile.given_names.length > 1}
								<RemoveIconButton
									dataTest="remove-given_name-btn"
									on:click={() => (confirmFirstNameDelete = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if confirmFirstNameDelete && confirmFirstNameDelete === value}
							<Modal
								dataTest="given_name-confirm-deletion-modal"
								position="left"
								class="md:min-w-[22rem] top-10"
								on:close={() => (confirmFirstNameDelete = null)}
							>
								<div class="flex flex-col md:flex-row items-center justify-between">
									<span>{$_('Confirm Deletion')}</span>
									<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-given_name-btn"
											on:click={() => (confirmFirstNameDelete = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-given_name-btn"
											on:click={() => removeFirstName(index)}
											isLoading={removeFirstNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-given_name-btn"
			on:click={() => (showAddFirstNameModal = true)}
			class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add first name')}</button
		>

		{#if showAddFirstNameModal}
			<Modal position="left" on:close={() => (showAddFirstNameModal = false)}>
				<VerifyName type="given-name" addName={addFirstName} ajaxRequest={addFirstNameAjax} />
			</Modal>
		{/if}
	</div>

	<!-- Last Names -->
	<section data-test="family_name-section" class="py-2">
		<h3 class="opacity-80 text-sm">
			{$_('Last Names')}
		</h3>

		{#if $profileData.profile.family_names?.length}
			<ul class="mt-2 space-y-2">
				{#each dedupe($profileData.profile.family_names) as value, index (value)}
					<li
						data-test="family_name-item-{index}"
						class="relative flex items-center flex-shrink-0"
						animate:flip={{ duration: flip_animation_ms }}
						transition:slide|local
					>
						<div class="w-6 h-6 inline-flex items-center">
							{#if $profileData.profile.family_names.length > 1}
								<RemoveIconButton
									dataTest="remove-family_name-btn"
									on:click={() => (confirmLastNameDelete = value)}
								/>
							{/if}
						</div>

						<span>{value}</span>

						{#if confirmLastNameDelete && confirmLastNameDelete === value}
							<Modal
								dataTest="family_name-confirm-deletion-modal"
								position="left"
								class="md:min-w-[22rem] top-10"
								on:close={() => (confirmLastNameDelete = null)}
							>
								<div class="flex flex-col md:flex-row items-center justify-between">
									<span>{$_('Confirm Deletion')}</span>
									<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
										<button
											data-test="cancel-deletion-family_name-btn"
											on:click={() => (confirmLastNameDelete = null)}
											class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
										>
										<RemoveButton
											dataTest="confirm-deletion-family_name-btn"
											on:click={() => removeLastName(index)}
											isLoading={removeLastNameAjax === value}
											value="Delete"
										/>
									</div>
								</div>
							</Modal>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</section>

	<div class="relative w-3/4 md:w-[20rem] {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}">
		<button
			data-test="add-family_name-btn"
			on:click={() => (showAddLastNameModal = true)}
			class="mb-1 btn-background h-6 px-2 text-sm">{$_('Add last name')}</button
		>

		{#if showAddLastNameModal}
			<Modal position="left" on:close={() => (showAddLastNameModal = false)}>
				<VerifyName type="family-name" addName={addLastName} ajaxRequest={addLastNameAjax} />
			</Modal>
		{/if}
	</div>
</div>
