<script>
	import TitleBar from '$lib/TitleBar.svelte';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import {
		data,
		showSpinner,
		notification,
		showUpgradePreferredModal,
		mergeAccounts
	} from '$src/stores.js';
	import Notification from '$lib/Notification.svelte';
	import { getProfile } from '$utils/api.js';
	import { onMount } from 'svelte';
	import { setAttributes } from '$utils/common.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	// import {
	// 	promptForPasskey
	// } from '$utils/passkey.js';
	import LoginProfile from '$lib/LoginProfile.svelte';

	// let showPasskeyLogin = false;

	onMount(async () => {
		$showSpinner = true;

		//remove all outstanding modals
		$showUpgradePreferredModal = null;
		$mergeAccounts = null;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
			} catch (err) {
				clearLocalAndSessionStorage();
			}
		}

		if ($data.isPersonalLoggedIn) return replaceRoute('/');
		if ($data.isManagedLoggedIn && !$data?.preferred?.length)
			return replaceRoute('/wizard/welcome');

		//uncomment to start prompting for passkey
		// showPasskeyLogin = (await promptForPasskey()) && $data.hasPasskey;

		logPlausibleEvent({ u: '/login' });
		$showSpinner = false;
	});
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<main class="flex-1 pb-16 overflow-y-auto">
		<div class="px-6 max-w-md container mx-auto mt-8 text-center">
			<LoginProfile />
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
