<script>
	import { notification } from '$src/stores.js';
	import { _, locale, locales } from 'svelte-i18n';
	import Dropdown from '$lib/Dropdown.svelte';
	import { slide } from 'svelte/transition';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import { changeLanguage } from '$utils/api.js';

	export let expanded = false;
	export let editMode = false;
	export let handleDropdown = () => {};

	let changePreferredLanguageAjax = null;
	async function changePreferredLanguage(langShortCode) {
		try {
			changePreferredLanguageAjax = langShortCode;
			await changeLanguage(langShortCode);
			localStorage.setItem('lang', langShortCode);
			$locale = langShortCode;
			expanded = false;
			setTimeout(() => {
				notification.show(
					$_('Preferred language is now {language}', {
						values: {
							language: $_(langShortCode)
						}
					}),
					'success'
				);
			}, 150);
		} catch (err) {
			console.error(err);
		} finally {
			changePreferredLanguageAjax = null;
		}
	}
</script>

<section id="preferred-language" class="py-2 {!editMode ? 'mt-10' : 'mt-4'}">
	<h2 class="font-medium text-lg">{$_('Preferred Language')}</h2>
	<div
		class="relative flex items-center mt-2 {$locale && $locale.startsWith('ar') ? 'mr-6' : 'ml-6'}"
	>
		<Dropdown
			hasBackground={false}
			dataTest="change-preferred-language-btn"
			ariaLabel={$locales.includes($locale)
				? $_($locale)
				: $locale.includes('-') && $locales.includes($locale.split('-')[0])
				? $_($locale.split('-')[0])
				: $_('en')}
			{expanded}
			on:click={() => handleDropdown('language')}
		>
			<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
				{#if $locales.includes($locale)}
					{$_($locale)}
				{:else if $locale.includes('-') && $locales.includes($locale.split('-')[0])}
					{$_($locale.split('-')[0])}
				{:else}
					{$_('en')}
				{/if}
			</div>

			{#if expanded}
				<div
					class="rounded-md space-y-2 text-left w-full z-30 p-4 flex flex-col"
					transition:slide|local
				>
					{#each [...$locales].filter((i) => i !== $locale && !$locale.startsWith(i)) as l}
						<button
							data-test={`${l}-language-btn`}
							on:click={() => changePreferredLanguage(l)}
							class="px-3 {$locale && $locale.startsWith('ar')
								? 'text-right'
								: 'text-left'} btn-background {changePreferredLanguageAjax === l
								? 'flex items-center justify-center'
								: ''}"
							disabled={changePreferredLanguageAjax === l}
						>
							{#if changePreferredLanguageAjax === l}
								<SpinnerIcon css="h-5 w-5 mx-auto" />
							{:else}
								{$_(l)}
							{/if}
						</button>
					{/each}
				</div>
			{/if}
		</Dropdown>
	</div>
</section>
