import Login from './login.svelte';
import Profile from './index.svelte';

//Wizard
import Welcome from './wizard/welcome.svelte';
import PreferredProvider from './wizard/preferredprovider.svelte';
import Status from './wizard/status.svelte';
import RecoveryProvider from './wizard/recoveryprovider.svelte';
import Incomplete from './wizard/incomplete.svelte';
import Upgrade from './wizard/upgrade.svelte';
import Passkey from './wizard/passkey.svelte';
import Managed from './wizard/managed.svelte';
import Email from './wizard/email.svelte';

export default {
	'/': Profile,
	'/login': Login,

	//Wizard
	'/wizard/welcome': Welcome,
	'/wizard/preferredprovider': PreferredProvider,
	'/wizard/status': Status,
	'/wizard/recoveryprovider': RecoveryProvider,
	'/wizard/incomplete': Incomplete,
	'/wizard/upgrade': Upgrade,
	'/wizard/passkey': Passkey,
	'/wizard/email': Email,
	'/wizard/managed': Managed,

	// The catch-all route must always be last
	'*': Login
};
