<script>
	import { push } from 'svelte-spa-router';
	import { data, notification, showSpinner } from '$src/stores.js';
	import { _ } from 'svelte-i18n';
	import {
		postLoginProvider,
		postLoginEth,
		postLoginEthChallenge,
		getProfile
	} from '$utils/api.js';
	import { getRecommendedProviders } from '$utils/common.js';
	import { trimEthAddress, getWallet } from '$utils/ethereum.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { handleConsentResponse } from '$utils/consent.js';
	// import { WALLETCONNECT_CONFIG } from '$src/constants.js';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	// import { getAddressFromAccount } from '$utils';
	import RecommendedProviders from './login/RecommendedProviders.svelte';
	import RecoveryStatus from './login/RecoveryStatus.svelte';
	import LoginPreferred from './login/LoginPreferred.svelte';
	import LoginManaged from './login/LoginManaged.svelte';
	import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte';

	// let web3ModalSign;

	export let provider_hints = {
		shown: [],
		hidden: []
	};

	const state = Object.preventExtensions({
		button: {
			show_recoveries: false,
			show_all: false,
			email: {
				default: false
			},
			phone: {
				default: false
			},
			provider: null,
			managed: false,
			personal: false
		},
		modal: {
			personalLoginRequired: false,
			managedLoginRequired: false
		}
	});
	let ethereumProgressModal;
	let ethereumProgressNotifs = [];

	const recommendedProviders = getRecommendedProviders(provider_hints.shown, provider_hints.hidden);

	async function contactLoginSuccess(e) {
		const res = await contactLogin(e.detail);
		if (!res?.uri && !res?.response_mode) {
			push('/');
		}
	}

	async function contactLogin() {
		try {
			$data = await getProfile();
		} catch (err) {
			console.error(err);
		}
	}

	function handleContactError(err) {
		if (err.detail.status !== 404) {
			state.button.show_all = state.button.phone.default = state.button.email.default = false;
		}
	}

	async function continueWithEthereumExtension({ info, account } = {}) {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		continueEthExtensionSigning(address, account);
	}

	async function continueEthExtensionSigning(address, account) {
		let challenge, signature;
		const slug = getWallet().slug;
		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/login/ethereum/extension/${slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Logging you in')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				notification.show($_("You've rejected the sign request"), 'error');
			} else {
				notification.show($_('Something went wrong. Please try again later.'), 'error');
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name,
			account
		};

		try {
			const res = await postLoginEthChallenge(body);
			await logPlausibleEvent({ u: `/login/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$data = await getProfile();
			if (!res?.uri && !res?.response_mode) {
				push('/');
			} else {
				$showSpinner = true;
				handleConsentResponse(res);
			}
		} catch (err) {
			console.error(err);
			notification.show($_('Something went wrong. Please try again later.'), 'error');
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	async function continueWithProvider({ slug, body, server } = {}) {
		try {
			$showSpinner = true;
			const { redirect } = await postLoginProvider({
				slug,
				body,
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect({ info } = {}) {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch (err) {
	// 		console.error(err);
	// 		setTimeout(() => {
	// 			//tbd : remove timeout - something is unsetting notification here
	// 			notification.show(
	// 				'Something went wrong',
	// 				'error'
	// 			);
	// 		}, 150);
	// 		return;
	// 	}

	// 	if (!info?.login_hint && session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_('Expected ({address})', {
	// 					values: {
	// 						address: trimEthAddress(info.login_hint)
	// 					}
	// 				}),
	// 				type: 'error',
	// 				status: address
	// 			}
	// 		];
	// 		return;
	// 	}
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address);
	// 	}, 1000);
	// }

	// async function continueWalletConnectSigning(address) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLoginEth(address);
	// 		logPlausibleEvent({ u: `/start/login/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		notification.show(
	// 			'Something went wrong',
	// 			'error'
	// 		);
	// 		return;
	// 	}

	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs[1] = {
	// 			text: $_('Message signed'),
	// 			type: 'success',
	// 			status: $_('Logging you in')
	// 		};
	// 	} catch (err) {
	// 		console.info(err);
	// 		notification.show(
	// 			$_(`You've rejected the sign request`),
	// 			'error'
	// 		);
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name
	// 	};

	// 	try {
	// 		const res = await postLoginEthChallenge(body);
	// 		await logPlausibleEvent({
	// 			u: `/login/ethereum/walletconnect/${slug}`,
	// 			n: 'action'
	// 		});
	// 		$data = await getProfile();
	// 		if (!res?.uri && !res?.response_mode) {
	// 			push('/');
	// 		} else {
	// 			$showSpinner = true;
	// 			handleConsentResponse(res);
	// 		}
	// 	} catch (err) {
	// 		console.error(err);
	// 		notification.show(
	// 			$_('Something went wrong. Please try again later.'),
	// 			'error'
	// 		);
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 	}
	// }

	async function handleManagedEmailSuccess() {
		try {
			$data = await getProfile();
			return push('/');
		} catch (err) {
			console.error(err);
		}
	}

	$: recoveryIds = $data?.recovery?.map((i) => i.id) || [];
	$: recoverable = $data?.preferred?.length && recoveryIds.length >= 2;
	$: loggedInSubject = $data?.recovery?.find((i) => i.id === $data?.subjects[0]) || {};
	$: isRecoveryMode =
		!$data?.isPersonalLoggedIn &&
		!!$data?.subjects?.find((subject) => recoveryIds.includes(subject));
	$: isManagedRecoveryMode = !$data?.isPersonalLoggedIn && $data?.isManagedLoggedIn;
	$: hasPreferred = $data?.preferred?.length;
	$: hasManaged = $data?.managed?.length;
	$: isManagedLoggedIn = $data?.isManagedLoggedIn;
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

{#if !hasPreferred && hasManaged && !isManagedLoggedIn}
	<LoginManaged
		heading={$_('Your Hellō Wallet requires a personal account')}
		label={$_('To set one up:<br/>Log in with your managed account')}
		{continueWithProvider}
		{contactLoginSuccess}
		{handleContactError}
		accounts={$data?.managed}
	/>
{:else}
	{#if isRecoveryMode}
		<RecoveryStatus
			{continueWithProvider}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{loggedInSubject}
		/>
	{/if}

	{#if !isRecoveryMode || (isManagedRecoveryMode && !recoverable)}
		<h1 class="relative flex items-center justify-center text-lg font-medium mb-6">
			{#if !hasPreferred && hasManaged}
				{$_('Your Hellō Wallet requires a personal account')}
			{:else}
				{$_('Log in to view and manage your Hellō Wallet')}
			{/if}
		</h1>

		{#if hasPreferred}
			<LoginPreferred
				accounts={$data.preferred}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
				{continueWithProvider}
			/>
		{:else}
			<RecommendedProviders
				{recommendedProviders}
				showManagedLogin={false}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
				{continueWithProvider}
				{handleManagedEmailSuccess}
			/>
		{/if}
	{/if}
{/if}
