<script>
	import RemoveIcon from '$lib/icon/RemoveIcon.svelte';

	export let dataTest = '';
	let hover = false;
</script>

<button
	aria-label="Remove"
	data-test={dataTest}
	on:mouseenter={() => (hover = true)}
	on:mouseleave={() => (hover = false)}
	on:focus={() => (hover = true)}
	on:blur={() => (hover = false)}
	on:click
	class="inline-flex items-center justify-start"
>
	<RemoveIcon {hover} />
</button>
