<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo } from 'svelte-spa-router';
	import { data as profileData } from '$src/stores.js';
	import FullPageModal from '$lib/modal/FullPageModal.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import CloseIcon from '$lib/icon/CloseIcon.svelte';
	import { deleteProfile } from '$utils/api.js';
	import TrashIcon from '$lib/icon/TrashIcon.svelte';
	import DownloadIcon from '$lib/icon/DownloadIcon.svelte';

	export let editMode = false;

	let deleteConfirmationInput = '';
	let confirmProfileDelete = false;

	function downloadData() {
		const downloadData = JSON.parse(JSON.stringify($profileData));
		delete downloadData.isPersonalLoggedIn;
		delete downloadData.isManagedLoggedIn;
		delete downloadData.preferred;
		delete downloadData.recovery;
		delete downloadData.subjects;
		const dataStr =
			'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(downloadData, null, 2));
		const dlAnchorElem = document.getElementById('downloadAnchorElem');
		dlAnchorElem.setAttribute('href', dataStr);
		let fileName = 'Hellō-Profile.json';
		dlAnchorElem.setAttribute('download', fileName);
		dlAnchorElem.click();
	}

	let delProfileAjax = false;
	async function delProfile() {
		try {
			delProfileAjax = true;
			await deleteProfile();
			$profileData = {};
			navigateTo('/login');
		} catch (err) {
			delProfileAjax = false;
			console.error(err);
		}
	}
</script>

<section class="my-8 relative">
	<div class="mt-2 flex justify-between items-center flex-wrap gap-4">
		<button
			on:click={downloadData}
			class="btn-border inline-flex items-center px-4 h-9 font-medium gap-x-1"
		>
			<DownloadIcon />
			<span class="mt-0.5">{$_('Download Your Data')}</span>
		</button>
		<!-- svelte-ignore a11y-missing-content -->
		<a id="downloadAnchorElem" hidden />

		{#if editMode}
			<button
				data-test="profile-delete-btn"
				on:click={() => (confirmProfileDelete = true)}
				class="btn-danger inline-flex items-center px-4 h-9 font-medium gap-x-1"
			>
				<TrashIcon />
				<span class="mt-0.5">{$_('Delete Your Wallet')}</span>
			</button>
		{/if}
	</div>

	{#if editMode && confirmProfileDelete}
		<FullPageModal
			dataTest="profile-delete-modal"
			on:close={() => {
				confirmProfileDelete = false;
				deleteConfirmationInput = '';
			}}
		>
			<button
				on:click={() => {
					confirmProfileDelete = false;
					deleteConfirmationInput = '';
				}}
				aria-label="Close"
				class="group absolute right-4 top-6"
			>
				<CloseIcon />
			</button>
			<p class="text-center">
				{@html $_(
					'Once you delete your wallet,<br/>there is no going back<br/>Please type DELETE to confirm'
				)}
			</p>
			<form on:submit|preventDefault={delProfile} class="w-full">
				<!-- svelte-ignore a11y-autofocus -->
				<input
					autofocus
					data-test="profile-delete-input"
					bind:value={deleteConfirmationInput}
					type="text"
					name="delete-confirmation-input"
					class="my-4 h-12 px-4 w-full"
					placeholder="DELETE"
				/>
				<button
					data-test="confirm-profile-delete-btn"
					disabled={deleteConfirmationInput !== 'DELETE' || delProfileAjax}
					type="submit"
					class="w-full h-12 btn-danger-background inline-flex items-center justify-center"
				>
					{#if delProfileAjax}
						<SpinnerIcon css="h-6 w-6" />
					{:else}
						{$_('Permanently delete your wallet')}
					{/if}
				</button>
			</form>
		</FullPageModal>
	{/if}
</section>
