<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '$lib/TitleBar.svelte';
	import { data, showSpinner, notification, showUpgradePreferredModal } from '$src/stores.js';
	import { getProfile, putPreferred } from '$utils/api.js';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import Notification from '$lib/Notification.svelte';
	import { PutAuthorityButton } from '$lib/button/';
	import { setAttributes, getDisplay, dedupe } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';
	import tippy from 'sveltejs-tippy';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import PhoneIcon from '$lib/icon/PhoneIcon.svelte';
	import InfoIcon from '$lib/icon/InfoIcon.svelte';
	import FullPageModal from '$lib/modal/FullPageModal.svelte';

	onMount(async () => {
		$showSpinner = true;

		//remove upgrade modal in upgrade page
		$showUpgradePreferredModal = null;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
				if (!$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}
				if ($data.actions?.doneWizardAt) return replaceRoute('/');

				//check if preferred is social
				const preferred = $data.profile?.accounts?.find((i) => i.preferred);
				const nonSocialProviders = ['email', 'phone'];
				const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
				if (isPreferredSocial) {
					nextPage();
				}
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.preferred?.length) return replaceRoute('/wizard/preferredprovider');

		localStorage.setItem('currentWizardStage', 'upgrade');

		//make preferred after verifying provider
		if (sessionStorage.getItem('authority_preferred')) {
			const res = await putPreferred({ preferred: sessionStorage.getItem('authority_preferred') });
			$data.profile.accounts = res.accounts;
			sessionStorage.removeItem('authority_preferred');
			nextPage();
		}

		logPlausibleEvent({ u: '/wizard/upgrade' });

		$showSpinner = false;
	});

	const delay = 50;
	let showVerifyModal = null;
	let updatePreferredAjax = null;

	function sortByCreatedDate(a, b) {
		return Date.parse(a.createdAt) - Date.parse(b.createdAt);
	}

	function nextPage() {
		//End of Wiz Upgrade Funnel
		sessionStorage.removeItem('wiz_upgrade_funnel');

		const recoveries = $data.profile?.accounts?.filter(
			(i) => !i.preferred && i.recovery && !i.communal
		);
		if (recoveries.length < 2) {
			return navigateTo('/wizard/incomplete');
		} else {
			return navigateTo('/wizard/status');
		}
	}

	async function updatePreferred(account) {
		try {
			updatePreferredAjax = account.id;
			const isAccountSocial = !['ethereum', 'email', 'phone'].includes(account.slug);

			const res = await putPreferred(
				{ preferred: account.id },
				{
					redirectPathParam: isAccountSocial, // only send redirect_path param if social provider
					server: account.mastodonServer || null // only send server param if mastodon
				}
			);

			//End of Wiz Upgrade Funnel
			const isInWizard = !$data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
			const contact_slug = $data.upgrade?.preferred?.slug;
			const email_domain = $data.upgrade?.preferred?.user_name?.split('@')?.[1];
			const upgrade_slug = $data.upgrade?.upgrade?.slug;
			// wizard upgrade funnel state is not already sent + is in wizard + is wallet app
			if (sessionStorage.wiz_upgrade_funnel && isInWizard && window.isWalletApp) {
				logPlausibleEvent({
					n: 'Wiz Upgrade Success',
					p: { contact_slug, email_domain, upgrade_slug },
					u: '/wizard/upgrade'
				});
				sessionStorage.removeItem('wiz_upgrade_funnel');
			}

			notification.show(
				$_('Changing preferred to {provider}', {
					values: {
						provider: getDisplay(account.slug)
					}
				}),
				'success'
			);
			if (res.verify) {
				showVerifyModal = { id: account.id, ...res.verify };
				if (res.verify.slug === 'mastodon') {
					//Do not show Mastodon dropdown UI - show continue with Mastodon account button
					showVerifyModal.mastodonServer = $data.profile?.accounts?.find(
						(i) => i.id === account.id
					).mastodonServer;
					showVerifyModal.user_name = $data.profile?.accounts?.find(
						(i) => i.id === account.id
					).user_name;
				}
			} else {
				$data.profile.accounts = res.accounts;
				setTimeout(() => {
					notification.clear();
					nextPage();
				}, 1000);
			}
		} catch (err) {
			updatePreferredAjax = null;
			console.error(err);
		}
	}

	const displayString = (account) => {
		let string = '';
		if (account.slug === 'ethereum') {
			string += account.wallet?.name;
		} else if (account.slug === 'email' || account.slug === 'phone') {
			// Do nothing
		} else {
			string += getDisplay(account.slug);
		}
		if (account.slug === 'email') {
			string += account.user_name;
		} else if (account.slug === 'phone') {
			string += window.intlTelInputUtils.formatNumber(
				account.user_name,
				null,
				window.intlTelInputUtils.numberFormat.INTERNATIONAL
			);
		} else if (account.user_name) {
			if (account.mastodonServer) {
				string += ` (@${account.user_name}@${account.mastodonServer})`;
			} else {
				string += ` (${account.user_name})`;
			}
		}
		return string;
	};

	function recoveryDisplayString() {
		const recoveries = $data.profile?.accounts
			?.filter(
				(i) =>
					!i.preferred &&
					i.recovery &&
					!i.communal &&
					!['email', 'phone', 'ethereum'].includes(i.slug)
			)
			.map((i) => i.slug);
		let deduped = [...new Set(recoveries)];
		deduped = deduped.map((i) => getDisplay(i));
		deduped = deduped.toString().replace(/,/g, ', ');
		return deduped;
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if showVerifyModal}
	<FullPageModal dataTest="verify-preferred-modal" on:close={() => (showVerifyModal = null)}>
		<h3 class="mb-4 font-medium text-center">{$_('Verify your account')}</h3>
		<LoginProvider
			provider={showVerifyModal}
			on:click={async () => {
				sessionStorage.setItem('authority_preferred', showVerifyModal.id);
				await logPlausibleEvent({ u: `/start/verify/${showVerifyModal.slug}`, n: 'action' });
				window.location.href = showVerifyModal.redirect_uri;
			}}
			prefix={$_('Verify')}
		/>
		<button
			class="mt-4 focus:underline hover:underline text-sm mr-auto block"
			on:click={() => (showVerifyModal = null)}>{$_('Cancel')}</button
		>
	</FullPageModal>
{/if}

{#if $data?.isPersonalLoggedIn && !$showSpinner}
	<main class="flex-1 overflow-y-auto pb-16" in:fade={{ delay: 0 * delay }}>
		<div class="mt-8 mb-6">
			<div
				class="max-w-2xl mx-auto px-3 flex flex-col items-center justify-between space-x-2 md:space-x-0 text-sm"
				in:fade={{ delay: 0 * delay }}
			>
				<h1 class="text-center flex-1 border-none text-base md:text-xl font-medium">
					{$_('Change Preferred Login Provider?')}
				</h1>

				<h2
					class="text-center max-w-md mx-auto border-none text-base md:text-lg font-medium italic mt-3"
				>
					{#if $data?.profile?.accounts.find((i) => i.preferred).slug === 'email'}
						{$_('Logging in with {provider} is generally more secure than email', {
							values: {
								provider: recoveryDisplayString()
							}
						})}
					{:else if $data?.profile?.accounts.find((i) => i.preferred).slug === 'phone'}
						{$_('Logging in with {provider} is generally more secure than phone', {
							values: {
								provider: recoveryDisplayString()
							}
						})}
					{/if}
				</h2>
			</div>
			<div class="px-3 md:px-0 md:max-w-2xl mx-auto text-center">
				<ul class="max-w-md mx-auto space-y-3 my-6">
					{#each dedupe([...$data.profile.accounts], 'id')
						.filter((i) => i.recovery && !i.communal && i.verifiedAt && i.slug !== 'ethereum')
						.sort(sortByCreatedDate)
						.sort((a, b) => b.preferred - a.preferred) as account, index (account.id)}
						<li
							data-test="recovery-provider-item-{index}"
							class="w-full rounded-md text-left
								{account.preferred
								? 'bg-charcoal bg-opacity-80 text-white dark:text-[#D4D4D4] rounded-md p-3 mb-6'
								: 'py-1'}"
							in:fade={{ delay: (index + 1) * delay }}
						>
							{#if account.preferred}
								<label for="preferred-provider" class="text-lg font-bold"
									>{$_('Current Preferred Provider')}</label
								>
								<div class="flex items-center w-full my-1 ml-4">
									<div class="w-4.5 max-h-[18px] h-9 inline-flex items-center">
										{#if account.slug === 'ethereum'}
											<img src={account.wallet.icon} alt={account.wallet.name} class="h-4" />
										{:else if account.slug === 'email'}
											<MailIcon />
										{:else if account.slug === 'phone'}
											<PhoneIcon />
										{:else if account.mastodonServer}
											<!-- TBD Broken -- Defaulting to default mastodon logo -->
											<!-- <img
												src="https://{account.mastodonServer}/favicon.ico"
												alt="Mastodon"
												class="h-6"
											/> -->
											<img
												src="https://cdn.hello.coop/images/mastodon.svg"
												alt="Mastodon"
												class="h-6"
											/>
										{:else}
											<img
												src="https://cdn.hello.coop/images/{account.slug}.svg"
												alt="{account.slug} logo"
												class="h-6 {['apple', 'twitter'].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
													? 'invert dark:invert-20'
													: ''}"
											/>
										{/if}
									</div>
									<div data-test="preferred-provider-label" id="preferred-provider" class="ml-1.5">
										{#if account.slug === 'ethereum'}
											<span use:tippy={{ content: account.login_hint, placement: 'top' }}>
												{displayString(account)}
											</span>
										{:else}
											<span>
												{displayString(account)}
											</span>
										{/if}
									</div>
								</div>
								{#if account.slug === 'email'}
									<span class="text-sm my-2 italic font-light"
										>{$_('Your preferred login is email with verification code')}</span
									>
								{:else if account.slug === 'phone'}
									<span class="text-sm my-2 italic font-light"
										>{$_('Your preferred login is phone with verification code')}</span
									>
								{/if}
							{:else}
								<div class="flex items-center">
									<div class="mt-0.5 flex-1 w-full">
										<div class="flex items-center justify-between flex-1">
											<div class="text-left font-medium w-full truncate inline-flex items-center">
												<div class="w-9 flex-shrink-0">
													{#if account.slug === 'ethereum'}
														<img src={account.wallet.icon} alt={account.wallet.name} class="h-4" />
													{:else if account.slug === 'email'}
														<MailIcon />
													{:else if account.slug === 'phone'}
														<PhoneIcon />
													{:else if account.mastodonServer}
														<!-- TBD Broken -- Defaulting to default mastodon logo -->
														<!-- <img
															src="https://{account.mastodonServer}/favicon.ico"
															alt="Mastodon"
															class="h-6"
														/> -->
														<img
															src="https://cdn.hello.coop/images/mastodon.svg"
															alt="Mastodon"
															class="h-6"
														/>
													{:else}
														<img
															src="https://cdn.hello.coop/images/{account.slug}.svg"
															alt="{account.slug} logo"
															class="h-6 {['apple', 'twitter'].includes(account.slug) //These logos are solid white and do not work on light backgrounds. TBD: use {provider}-light.svg & provider-{dark}.svg for all providers.
																? 'invert dark:invert-20'
																: ''}"
														/>
													{/if}
												</div>
												<span class="block truncate" class:font-bold={account.preferred}>
													{#if account.slug === 'ethereum'}
														<span use:tippy={{ content: account.login_hint, placement: 'top' }}>
															{displayString(account)}
														</span>
													{:else}
														<span>
															{displayString(account)}
														</span>
													{/if}
												</span>
											</div>

											<div class="flex-shrink-0 ml-4">
												{#if ['email', 'phone', 'ethereum'].includes(account.slug)}
													<span class="text-xs font-medium uppercase">{$_('Recovery')}</span>
												{:else}
													<PutAuthorityButton
														dataTest="make-preferred-{index}-btn"
														on:click={() => updatePreferred(account)}
														value="Make Preferred"
														isLoading={updatePreferredAjax === account.id}
													/>
												{/if}
											</div>
										</div>
									</div>
								</div>
							{/if}
						</li>
					{/each}
				</ul>

				<div
					class="max-w-md mx-auto"
					in:fade={{ delay: ($data.profile?.accounts.length + 2) * 50 }}
				>
					<button
						data-test="not-now-btn"
						on:click={nextPage}
						class="focus:underline hover:underline disabled:opacity-50 disabled:cursor-wait disabled:no-underline mr-auto block"
					>
						{$_('Not now')}
					</button>

					<div class="mt-4 opacity-60 max-w-md w-full mx-auto flex items-center text-sm">
						<InfoIcon />
						<span class="ml-2">{$_('You can change your providers at wallet.hello.coop')}</span>
					</div>
				</div>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
