import Index from './pages/Index.svelte';
import './index.css';
import './i18n.js';
import 'intl-tel-input/build/js/utils.js';

const app = new Index({
	target: document.body
});

export default app;
