<script>
	import { _ } from 'svelte-i18n';
	import { slide, fade } from 'svelte/transition';
	import { replace as replaceRoute, push as navigateTo } from 'svelte-spa-router';
	import TitleBar from '$lib/TitleBar.svelte';
	import { showSpinner } from '$src/stores.js';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import MailIcon from '$lib/icon/MailIcon.svelte';
	import { data, notification } from '$src/stores.js';
	import Dropdown from '$lib/Dropdown.svelte';
	import { getProfile } from '$utils/api.js';
	import LoginProviderGroup from '$lib/LoginProviderGroup.svelte';
	import { onMount } from 'svelte';
	import Notification from '$lib/Notification.svelte';
	import { setAttributes } from '$utils/common.js';
	import { clearLocalAndSessionStorage } from '$utils/storage.js';

	let continueWithEmail = false;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
				if (!$data.isPersonalLoggedIn && !$data.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}
				if ($data.actions?.doneWizardAt) return replaceRoute('/');
			} catch {
				return replaceRoute('/login');
			}
		}

		if ($data.preferred?.length) return replaceRoute('/wizard/status');

		localStorage.setItem('currentWizardStage', 'preferredprovider');
		$showSpinner = false;
	});

	async function verifyEmailSuccess() {
		try {
			continueWithEmail = false;
			$data = await getProfile();
			notification.clear(); //remove outstanding notification
			return navigateTo('/');
		} catch (err) {
			console.error(err);
		}
	}

	const delay = 50;
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	{@const managed = $data?.managed?.find((i) => $data?.subjects?.includes(i.id))}
	<main class="flex-1 px-3 md:px-0 overflow-y-auto pb-16">
		{#if managed}
			<section
				class="bg-[#DCDCDC] dark:bg-[#505050] rounded-md px-2 pt-2 pb-3 max-w-md mx-auto mt-8"
				in:fade={{ delay }}
			>
				<span class="block text-sm font-medium text-left">{$_('You logged in with')}</span>
				<div
					class="flex flex-col justify-center items-start mt-2 px-2 rounded-md text-charcoal dark:text-[#D4D4D4]"
				>
					<div class="inline-flex items-center gap-x-2.5 mt-1">
						<span class="inline-flex items-center justify-center rounded-sm">
							<span class="managed-rounded-square-sm -ml-0">
								<img src={managed.logo} alt="logo" class="w-4.5 max-h-[18px] object-contain" />
							</span>
						</span>
						<span>
							{managed.user_name}
						</span>
					</div>
					<span class="block text-sm font-medium text-left mt-3"
						>{$_('an account managed by an organization')}</span
					>
				</div>
				<span class="block italic font-medium text-lg text-left mt-5"
					>{$_('You need a personal provider for your wallet')}</span
				>
			</section>
		{/if}

		<div>
			<h1
				class="my-6 text-center border-none text-base md:text-2xl font-medium mx-auto"
				in:fade={{ delay: 2 * delay }}
			>
				{$_('Select your preferred login provider')}
			</h1>

			<section class="mt-6 max-w-md mx-auto" in:fade={{ delay: 3 * delay }}>
				<LoginProviderGroup
					login={true}
					cryptoWallets={false}
					prefix="Continue with"
					showManagedLogin={false}
					choosePreferred={true}
				>
					<Dropdown
						dataTest="add-email-btn"
						expanded={continueWithEmail}
						ariaLabel={$_('Continue with Email')}
						on:click={() => {
							continueWithEmail = !continueWithEmail;
						}}
					>
						<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
							<MailIcon />
							<span class="block text-left" aria-hidden="true">
								{$_('Continue with Email')}
							</span>
						</div>
						{#if continueWithEmail}
							<div class="px-3 pb-3 pt-1" transition:slide|local>
								<VerifyEmail on:success={verifyEmailSuccess} login={true} choosePreferred={true} />
							</div>
						{/if}
					</Dropdown>
				</LoginProviderGroup>
			</section>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
