<script>
	import { onDestroy } from 'svelte';
	import { _ } from 'svelte-i18n';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	export let value = '';
	export let isLoading = false;
	export let dataTest = '';

	$: {
		isLoading
			? document.body.classList.add('cursor-wait')
			: document.body.classList.remove('cursor-wait');
	}

	onDestroy(() => {
		document.body.classList.remove('cursor-wait');
	});
</script>

<!-- svelte-ignore a11y-autofocus -->
<button
	autofocus
	data-test={dataTest}
	on:click
	class="btn-background text-sm h-9 w-20 px-0 inline-flex items-center justify-center"
	class:bg-opacity-50={isLoading}
	class:cursor-not-allowed={isLoading}
	disabled={isLoading}
>
	{#if isLoading}
		<SpinnerIcon css="h-4 w-4" />
	{:else}
		{$_(value)}
	{/if}
</button>
